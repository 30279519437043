import React from "react";
import styled from "styled-components";

const MessageStyle = styled.p`
text-align: center;
font-size: 40px;

`

const Message = () => {
  return <MessageStyle>It's time to d-d-d-d-d-d-d-d-duel!</MessageStyle>;
};

export default Message;
